<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest
                                        ?'(测试环境,数据定期删除)':''}}当前：资产负债表</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :sm="12" :md="4">
                                <vxe-toolbar class="pull-left" style=" height: 42px; " custom export ref="xToolbar2">
                                </vxe-toolbar>
                                <el-form-item label="会计期间:">
                                    <el-date-picker
                                            v-model="pickerDateMonth"
                                            style=" padding-top: 2px; width: 80px"
                                            type="month"
                                            align="right"
                                            size="mini"
                                            @change="$_pickerMonthEvent(),reportBalance()"
                                            unlink-panels
                                            :clearable="false"
                                            range-separator="至"
                                            start-placeholder="开始月份"
                                            end-placeholder="结束月份">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="7">
                                <el-button style=" margin-top: 8px; " type="danger" round
                                           :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="$_openType({path: '/allVoucher',
                                query: {subject_id : $refs.xGrid.getCurrentRecord().subject_id,startDate: formatDate(pickerDate[0],'yyyy-MM-dd'),
                                endDate: formatDate(pickerDate[1],'yyyy-MM-dd')}})" size="mini">明细
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="primary" round
                                           @click="reportBalance" size="mini">搜索
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                ref="xGrid"
                                id="xGridReportBalance"
                                :header-row-style="headerRowStyle"
                                :row-key="true"
                                row-id="id"
                                :export-config="{filename:'资产负债表',type: ['xlsx'] ,types: ['xlsx', 'csv', 'html', 'xml', 'txt'],isFooter:true,isColgroup:true}"
                                align="center"
                                :custom-config="{storage:true}"
                                @cell-dblclick="celldbClickEvent"
                                :mouse-config="{selected: true}"
                                :height="(this.getViewHeight() - 130)+''"
                                size="mini"
                                :cell-class-name="cellClassName"
                                :row-class-name="$_tableRowClassNameSuccess"
                                :data="tableDataMain"
                                :columns="tableColumn">
                        </vxe-grid>
                    </el-col>
                </el-row>

            </el-form>
        </el-main>
        <vxe-modal v-model="showMath" :lock-view="false"
                   :mask="false"
                   resize
                   margin-size="-500" width="900" :title="formData.math_title + '-公式'">
            <template #default>
                <el-form ref="formData" :model="formData" :rules="formDataRules" label-width="80px">
                    <el-row>
                        <el-col :sm="12" :md="8">
                            <el-form-item label="科目:" prop="subject_name" label-width="60px">
                                <el-select
                                        ref="subject_name" v-model="formData.subject_name"
                                        style="width: 100%"
                                        size="mini"
                                        clearable
                                        remote
                                        :remote-method="getRoleSubjectByAccountId"
                                        @change="selectChangeEvent($event)"
                                        filterable
                                        default-first-option
                                        placeholder="请选择">
                                    <el-option
                                            v-for="item in roleSubjectList"
                                            :key="item.id_subject"
                                            :label="item.id_subject +'-'+item.subject_name"
                                            :value="item">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :sm="12" :md="5">
                            <el-form-item label="运算符号:" label-width="90px">
                                <el-select
                                        v-model="formData.math_operator"
                                        style="width: 100%"
                                        size="mini"
                                        filterable
                                        default-first-option
                                        placeholder="请选择">
                                    <el-option
                                            v-for="item in mathSelectFHList"
                                            :key="item.label"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :sm="12" :md="5">
                            <el-form-item label="取数规则:" label-width="90px">
                                <el-select
                                        v-model="formData.fetch_rule"
                                        style="width: 100%"
                                        size="mini"
                                        filterable
                                        default-first-option
                                        placeholder="请选择">
                                    <el-option
                                            v-for="item in mathSelectGZList"
                                            :key="item.label"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :sm="12" :md="4">
                            <el-button type="primary" size="mini" @click="addOperate()" style="margin-top: 8px;margin-left: 5px">添加
                            </el-button>
                        </el-col>
                    </el-row>
                </el-form>
                <vxe-grid
                        border
                        ref="mathGrid"
                        show-overflow
                        align="center"
                        resizable
                        height="450"
                        size="mini"
                        highlight-current-row
                        :checkbox-config="{labelField:'id',range:true}"
                        :data="mathDataModel"
                        :columns="mathColumnModel"
                        :mouse-config="{selected: true}">
                    <template #operate="{row}">
                        <vxe-button @click="deleteOperate(row)">刪除</vxe-button>
                    </template>
                </vxe-grid>
            </template>
        </vxe-modal>
    </div>

</template>

<script>


    export default {

        data() {
            var now = new Date();
            var year = now.getFullYear();
            var month = now.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
            // 本月第一天
            var startDate = new Date(year + '-' + month + '-01 00:00:00');
            return {
                //自定义表头
                headerRowStyle:{fontSize: '14px'},
                showMath:false,
                type: '',//空代表默认，CN代表出纳用的（外购单一览）
                tableColumn: [
                    {
                        field: 'name_type', width: 290, title: '资 产',slots: {
                            // 使用 JSX 渲染
                            default: ({row}) => {
                                if (row.title_type == 'title' || row.title_type == 'sum') {
                                    return row.name_type
                                }else{
                                    if(row.title_type == 'false'){
                                        return [
                                            <el-tooltip class="item" effect="dark" placement="top">
                                            <div slot="content">
                                                {row.math} (双击编辑公式)
                                            </div>
                                            <i class="el-icon-info"> {row.name_type}</i>
                                            </el-tooltip>
                                        ]
                                    }
                                }
                            }
                        }
                    },
                    {
                        field: 'order_id', width: 80, title: '行次'
                    },
                    {
                        field: 'year_money_start', width: 160, title: '年初数',
                        formatter: ({row,cellValue}) => {
                            if (!this.isBlankVue(row.name_type) && row.name_type.indexOf('减') > 0){
                                return this.$XEUtils.commafy(cellValue ? 0 - cellValue : '', {digits: 2})
                            }else{
                                return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                            }
                        }
                    },
                    {
                        field: 'money_end', width: 160, title: '期末数',
                        formatter: ({row,cellValue}) => {
                            if (!this.isBlankVue(row.name_type) && row.name_type.indexOf('减') > 0){
                                return this.$XEUtils.commafy(cellValue ? 0 - cellValue : '', {digits: 2})
                            }else{
                                return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                            }
                        }
                    },
                    {
                        field: 'name_type2', width: 290, title: '负债和股东权益',slots: {
                            // 使用 JSX 渲染
                            default: ({row}) => {
                                if (row.title_type2 == 'title' || row.title_type2 == 'sum') {
                                    return row.name_type2
                                }else{
                                    if(row.title_type2 == 'false'){
                                        return [
                                            <el-tooltip class="item" effect="dark" placement="top">
                                                <div slot="content">
                                                    {row.math} (双击编辑公式)
                                                </div>
                                            <i class="el-icon-info"> {row.name_type2}</i>
                                            </el-tooltip>
                                        ]
                                    }
                                }
                            }
                        }
                    },
                    {
                        field: 'order_id2', width: 80, title: '行次'
                    },
                    {
                        field: 'year_money_start2', width: 160, title: '年初数',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                        }
                    },
                    {
                        field: 'money_end2', width: 160, title: '期末数',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                        }
                    },
                ],
                tableDataMain: [],
                mathDataModel: [],
                mathColumnModel: [
                    {
                        field: 'id_subject', width: 120, title: '科目编码'
                    },{
                        field: 'subject_name', width: 460, title: '科目名称'
                    },{
                        field: 'math_operator', width: 60, title: '运算符号'
                    },{
                        field: 'fetch_rule', width: 60, title: '取数规则'
                    },
                    {field:'operate',title: '操作',fixed:'right' , width: 75, slots: {default: 'operate'}}
                ],
                roleSubjectList:[],//授权科目，可以多选
                filterData: {},
                sortData: {"type_id": "asc"},
                pickerDate: [startDate, this.$getMonthLast(startDate)],
                pickerDateMonth: startDate,
                formData: {//搜索筛选
                    id_subject:'',
                    subject_name:'',
                    math_operator:'+',//取数符号
                    fetch_rule:'余额',//取数规则
                    math_title:'',
                    report_name:'资产负债表',
                },
                formDataRules: {
                    subject_name: [
                        { required: true, message: '请选择科目', trigger: 'blur' },
                    ],
                },
                mathSelectFHList:[{value: "+", label: "+"}, {value: "-", label: "-"}],
                mathSelectGZList:[{value: "余额", label: "余额"}]
            }
        }, computed: {},
        methods: {
            selectChangeEvent(item){
                this.formData.subject_name = item.subject_name
                this.formData.id_subject = item.id_subject
            },
            //添加
            addOperate(){
                this.$refs['formData'].validate((valid) => {
                    if (valid) {
                        this.$axios({
                            method:'post',
                            url:'/admin/reportBalance/addOperate',
                            data: {
                                formData: this.formData,
                            }
                        }).then((response) =>{         //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if(response.status === 200 && response.data.state == 'ok'){
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'success'
                                });
                                this.getReportMath();
                            }else{
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                                });
                                return
                            }
                        }).catch((error) =>{
                            console.log(error)
                        });
                    } else {
                        this.$message({
                            showClose: true,
                            message: '请选择科目',
                            type: 'error'
                        });
                        return false;
                    }
                });

            },
            //刪除
            deleteOperate(row){
                this.$axios({
                    method:'post',
                    url:'/admin/reportBalance/deleteReportMath',
                    params: {
                        id:row.id
                    }
                }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200 && response.data.state == 'ok'){
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'success'
                        });
                        this.getReportMath();
                    }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }
                }).catch((error) =>{
                    console.log(error)
                });
            },
            //获取不同用户的授权科目权限
            getRoleSubjectByAccountId(query){
                this.$axios({
                    method:'post',
                    url:'/admin/roleSubject/getRoleSubjectByAccountId',
                    data:{
                        query:query
                    }
                }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200){
                        this.roleSubjectList = response.data;
                    }
                }).catch((error) =>{
                    console.log(error)
                });
            },
            //双击提交状态，直接提交当前商品ID，设置对应反选状态
            celldbClickEvent ({ row, column}) {
                if ((column.property =='name_type' && row.title_type == 'false') || (column.property =='name_type2' && row.title_type2 == 'false')){
                    this.showMath = true
                    if (column.property =='name_type'){
                        this.formData.math_title = row.name_type
                        this.getReportMath()
                    }else if(column.property =='name_type2'){
                        this.formData.math_title = row.name_type2
                        this.getReportMath()
                    }
                }
            },
            getReportMath(){
                this.$axios({
                    method:'post',
                    url:'/admin/reportBalance/findReportMath',
                    data:{
                        query:this.formData.math_title
                    }
                }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200){
                        this.mathDataModel = response.data.page;
                        this.reportBalance()
                    }
                }).catch((error) =>{
                    console.log(error)
                });
            },
            openVoucher(row) {
                const {href} = this.$router.resolve({path: '/voucher', query: {id: row.id}})
                window.open(href, '_blank');
            },
            cellClassName({row, column}) {
                if(!this.isBlankVue(row.name_type) && row.name_type.indexOf('合计') > 0) {
                    return 'sumColorRed'
                }else if ((row.title_type == 'title' && column.property === 'name_type') || (row.title_type2 == 'title' && column.property === 'name_type2') ) {
                    return 'selectedColor'
                }else if((row.title_type == 'false' && column.property === 'name_type') || (row.title_type2 == 'false' && column.property === 'name_type2')) {
                    return 'pointer'
                }
                /* else {
                    return 'col-fail'
                    return 'col-success'
                }
                return 'col-ableClick'*/
            },
            reportBalance() {
                //this.tableDataMain = []
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let searchUrl = '/admin/reportBalance'
                this.$axios({
                    method: 'POST',
                    url: searchUrl,
                    data: {
                        sortData: this.sortData,
                        filterData: this.filterData,
                        pickerDate: this.pickerDate,
                        formData: this.formData
                    }
                }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataMain = response.data.page
                    }
                }).catch((error) => {
                    loading.close();
                    console.log(error)
                });
            },
        },
        created() {
            this.$nextTick(() => {
                if (!this.isBlankVue(this.$route.query.type)) {
                    this.type = this.$route.query.type;
                }
                this.getRoleSubjectByAccountId()
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
            })
        }
    };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
    }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
    }

    .el-form-item__content .el-input {
        width: 130px;
    }

    /deep/ .el-input--suffix .el-input__inner {
        padding-right: initial !important;
    }

    /deep/ input[popperclass="myAutocomplete"] {
        padding: 3px !important;
    }

    /deep/ .el-input__suffix {
        right: 0px !important;
    }
</style>
